.destaque {
  content: '';
  position: absolute;
  pointer-events: none;
}
.tour-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background: #1C1A1A7A;
  width: 100vw;
  height: 100vh;
}
.tour-container .fechar-tour {
  position: absolute;
  top: 0;
  right: 0;
}
.tour-content {
  position: absolute;
  background: #FFF;
  border-radius: 15px;
  padding: 40px 40px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tour-content:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border: 25px solid transparent;
}
.tour-container .arrow-left:before {
  border-right-color: #FFF;
  left: -45px;
  top: 125px;
}
.tour-container .arrow-right:before {
  border-left-color: #FFF;
  right: -45px;
  top: 14px;
}
.tour-container .arrow-top:before {
  border-bottom-color: #FFF;
  left: 25px;
  top: -48px;
}
.tour-container .arrow-top-end:before {
  border-bottom-color: #FFF;
  right: 15px;
  top: -48px;
}
.tour-container .steps {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}
.tour-container .steps .step {
  content: '';
  width: 10px;
  height: 10px;
  background: #D9D9D9;
  border-radius: 50%;
  cursor: pointer;
}
.tour-container .steps .step.active {
  background: #3C44B1 !important;
}
body.dark-mode .tour-container .tour-content {
  color: #FFF;
  background: #39405C;
}
body.dark-mode .tour-container .arrow-left:before {
  border-right-color: #39405C;
}
body.dark-mode .tour-container .arrow-right:before {
  border-left-color: #39405C;
}
body.dark-mode .tour-container .arrow-top:before {
  border-bottom-color: #39405C;
}
body.dark-mode .tour-container .arrow-top-end:before {
  border-bottom-color: #39405C;
}
body.dark-mode .tour-container .steps .step {
  background: #13192F;
}
@media (max-width: 1100px) {

  .destaque {
    content: '';
    position: absolute;
    pointer-events: none;
  }

  .tour-container {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    background: #1C1A1A7A;
    width: 100vw;
    height: 100vh;
  }

  .tour-container .fechar-tour {
    position: absolute;
    top: 0;
    right: 0;
  }

  .tour-content {
    position: absolute;
    background: #FFF;
    border-radius: 15px;
    padding: 40px 40px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tour-content:before {
    display: none;
  }

  .tour-container .steps {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
  }

  .tour-container .steps .step {
    content: '';
    width: 10px;
    height: 10px;
    background: #D9D9D9;
    border-radius: 50%;
    cursor: pointer;
  }

  .tour-container .steps .step.active {
    background: #3C44B1 !important;
  }

  body.dark-mode .tour-container .tour-content {
    color: #FFF;
    background: #39405C;
  }


  body.dark-mode .tour-container .steps .step {
    background: #13192F;
  }
}
